<div class="d-flex  flex-wrap">
  <ng-container *ngFor = "let document of documents">
    <div class="mx-2 my-1" *ngIf="document.extension == 'jpeg' || document.extension == 'jpg' || document.extension == 'png'"  style="width: 140px;height: 200px;border: dashed gainsboro;padding: 4px;">
        <a [href] = "document.Key" target="_blank" download style="height: 100%;display: flex;">
            <img [src]="document.Key" alt="" style="width: 100%;">
        </a>
        <p style="overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 13px;">{{ document.originalName }}</p>
    </div>
    <div class="mx-2 my-1" *ngIf="document.extension == 'doc' || document.extension == 'docx'"  style="width: 140px;height: 200px;border: dashed gainsboro;padding: 4px;">
        <a [href] = "document.Key" download target="_blank" style="height: 100%;display: flex; align-items: center;justify-content: center;">
            <img src="../../assets/icons/doc.png" alt="" width="70px" >
        </a>
        <p style="overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 13px;">{{ document.originalName }}</p>
    </div>
    <div class="mx-2 my-1" *ngIf="document.extension == 'pdf'"  style="width: 140px;height: 200px;border: dashed gainsboro;padding: 4px;">
        <a [href] = "document.Key" download target="_blank" style="height: 100%;display: flex; align-items: center;justify-content: center;">
            <img src="../../assets/icons/pdf.png" alt=""  width="70px" >
        </a>
        <p style="overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 13px;">{{ document.originalName }}</p>
    </div>
    <div class="mx-2 my-1" *ngIf="document.extension == 'dwg'"  style="width: 140px;height: 200px;border: dashed gainsboro;padding: 4px;">
        <a [href] = "document.Key" download target="_blank" style="height: 100%;display: flex; align-items: center;justify-content: center;">
            <img src="../../assets/icons/dwg.png" alt=""  width="70px" >
        </a>
        <p style="overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 13px;">{{ document.originalName }}</p>
    </div>
    <div class="mx-2 my-1" *ngIf="document.extension == 'xls'"  style="width: 140px;height: 200px;border: dashed gainsboro;padding: 4px;">
        <a [href] = "document.Key" download target="_blank" style="height: 100%;display: flex; align-items: center;justify-content: center;">
            <img src="../../assets/icons/xls.png" alt=""  width="70px" >
        </a>
        <p style="overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 13px;">{{ document.originalName }}</p>
    </div>
    <div class="mx-2 my-1" *ngIf="document.extension == 'rar' || document.extension == 'zip'"  style="width: 140px;height: 200px;border: dashed gainsboro;padding: 4px;">
        <a [href] = "document.Key" download target="_blank" style="height: 100%;display: flex; align-items: center;justify-content: center;">
            <img src="../../assets/icons/zip.png" alt=""  width="70px" >
        </a>
        <p style="overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 13px;">{{ document.originalName }}</p>
    </div>
  </ng-container>    
</div>