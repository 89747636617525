<div class="main-container" *ngIf=" projectData; else loading">
    <div class="container">
        <div class="heading-text">
            <p>{{projectData?.project.projectName}}<span class="image"><img src="../../assets/icons/chevron-right.svg" alt=""></span> <span style="color:  #0066FF;">Project Details</span></p>
        </div>
        <div class="content-box mb-5">
            <div class="card" >
                <div class="card-body mb-5"  >
                    <h4 style="font-weight: 700;">{{projectData?.project.projectName}}
                        <span class="status open" *ngIf="projectData?.project.status == 'open'">Open</span>
                        <span class="status ongoing" *ngIf="projectData?.project.status == 'ongoing'">Ongoing</span>
                        <span class="status completed" *ngIf="projectData?.project.status == 'completed'">Completed</span>
                        <span style="float: right;" data-toggle="modal" data-target="#shareModal"  *ngIf="projectData?.project.status == 'open'">
                           <img src="../../assets/icons/share.svg"> 
                         </span>                    </h4> 
                    <div class="d-flex my-4">
                        <!-- <div>
                            <img src="./../../assets/icons/user.svg" alt="" style="width: 50px;">
                        </div> -->
                        <div class="ml-3">
                            <p class="mb-0" style="font-weight: 500;">{{projectData?.project.clientID.username | titlecase}}</p>
                            <p class="mb-2" style="font-weight: bold;color: #0066ff;">Project Location:
                                <span style="color: black;  font-weight: normal;">{{projectData?.project.state | titlecase}}</span>
                               </p>
                        </div>
                    </div>

                    <h5 style="font-weight: 700;">Project Information</h5>

                                                   
                    <div  class="d-flex  mb-2">
                        <div class="d-flex  align-items-end" >
                            <div>
                                <img src="./../../assets/icons/license.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                            </div>
                            <div class="ml-2">
                                <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">License Type</p>
                                <p class="m-0" style="font-weight: 600;">{{projectData?.project.licenseType | titlecase}}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-end ml-3" *ngIf="projectData?.project.status == 'open'">
                            <div>
                                <img src="./../../assets/icons/bids.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                            </div>
                            <div class="ml-2">
                                <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Bids</p>
                                <p class="m-0" style="font-weight: 600;">{{projectData?.project.totalBids}}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-end ml-3" *ngIf="projectData?.project.status == 'ongoing' || projectData?.project.status == 'completed'">
                            <div>
                                <img src="./../../assets/icons/dollor.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                            </div>
                            <div class="ml-2">
                                <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Bid Value</p>
                                <p class="m-0" style="font-weight: 600;">{{projectData?.project.budget + projectData?.project.commission}}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-end ml-3" >
                            <div>
                                <img src="./../../assets/icons/calender.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                            </div>
                            <div class="ml-2">
                                <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Posted on</p>
                                <p class="m-0" style="font-weight: 600;">{{projectData?.project.createdAt | date:'short' : '-0800'}}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-end ml-3"  *ngIf="projectData?.project.status == 'completed'">
                            <div>
                                <img src="./../../assets/icons/calender.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                            </div>
                            <div class="ml-2">
                                <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;" >Ended on</p>
                                <p class="m-0" style="font-weight: 600;">{{projectData?.project.createdAt | date:'short' : '-0800'}}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-end ml-3" *ngIf="projectData?.project.engineerID?.username" >
                            <div>
                                <img src="./../../assets/icons/engineer.svg" alt="" style="width: 25px; margin-bottom: 5px;">
                            </div>
                            <div class="ml-2">
                                <p class="m-0" style="font-size: 14px;font-weight: 800;color: #B8BAC7;">Engineer</p>
                                <p class="m-0" style="font-weight: 600;">{{projectData?.project.engineerID?.username | titlecase}}</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-end ml-3" *ngIf="projectData?.project.engineerID?.engineer_rating" >
                            <div class="ml-2">
                                <p class="mb-0"  style="font-size: 14px;font-weight: 800;color: #B8BAC7;">AVG PE Rating</p>
                            <img src="../../assets/icons/star.svg" alt=""> <span style=" margin: 5px; font-size: 14px;font-weight: 600; ">{{projectData?.project.engineerID?.engineer_rating}}</span>
                            </div>
                        </div>
                        
                    </div>
                    <hr>
                    <h5 style="font-weight: 700;">Project Description</h5>
                    <p style="font-size: 15px;">{{projectData?.project.description}}</p>
                   
                    <h5 style="font-weight: 700;">Project Documents</h5>

                    <ul class="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">submitted by Client</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">submitted by PE</a>
                        </li>
                    </ul>
                      <div class="tab-content" id="pills-tabContent" >
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <app-emptystates *ngIf="clientDocs(projectData?.project_docs?.docs)?.length == 0; else showClientDocs"  [status]="'No Documents'"></app-emptystates>
                            <ng-template #showClientDocs>
                                <app-documents [documents] = "clientDocs(projectData?.project_docs?.docs)"></app-documents>
                            </ng-template>                           
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <app-emptystates *ngIf="engineerDocs(projectData?.project_docs?.docs).length == 0; else showengineerDocs" [status]="'No Documents'" ></app-emptystates>
                            <ng-template #showengineerDocs>
                                <app-documents  [documents] = "engineerDocs(projectData?.project_docs?.docs)"></app-documents>
                            </ng-template>                       
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loading>
    <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
        <app-loading></app-loading>
    </div>
</ng-template>


<div class="modal fade" id="shareModal" tabindex="-1" role="dialog" >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >Share Project</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <form>
                <div class="form-group">
                    <label for="recipient-name" class="col-form-label">Search Email</label>
                    <input type="text" class="form-control" id="recipient-name" #engineerSearchInput>
                  </div>
                  <div class="container d-flex align-items-center" *ngIf="emailShare?.engnr != undefined">
                      <h3 class="m-0 mr-2" style="font-size: 20px; font-weight: 600;">{{emailShare?.engnr.username}}</h3>
                      <button type="reset" class="btn btn-primary" (click)= "engrID = emailShare?.engnr._id; shareProjectPE() " data-dismiss="modal">Send</button>
                  </div>
                  <div class="container d-flex align-items-center" *ngIf="emailShare?.error != undefined">
                      <h3 class="m-0 mr-2" style="font-size: 20px; font-weight: 600;">No such engineer found!</h3>
                  </div>
            </form>
            
        </div>
      </div>
    </div>
  </div>

