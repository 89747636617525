import { DashboardGuard } from './dashboard.guard';
import { LoginGuard } from './login.guard';
import { MustMatchDirective } from './forgetpassword/mustmatch/must-match.directive';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectdetailComponent } from './projectdetail/projectdetail.component';
import { EmptystatesComponent } from './emptystates/emptystates.component';
import { EngineerComponent } from './engineer/engineer.component';
import { CustomerComponent } from './customer/customer.component';
import { EngineerdetailComponent } from './engineerdetail/engineerdetail.component';
import { CustomerdetailComponent } from './customerdetail/customerdetail.component';
import { CardComponent } from './card/card.component';
import { ChartsModule } from 'ng2-charts';
import { MyLineChartComponent } from './my-line-chart/my-line-chart.component';
import { DocumentsComponent } from './documents/documents.component';
import { LoadingComponent } from './loading/loading.component';
import { MyBarChartComponent } from './my-bar-chart/my-bar-chart.component';
import { MonthyearComponent } from './monthyear/monthyear.component';
import { TotalclientComponent } from './totalclient/totalclient.component';
import { TotalpeComponent } from './totalpe/totalpe.component';
import { TotalrevenueComponent } from './totalrevenue/totalrevenue.component';
import { SocketIoModule } from 'ngx-socket-io';
import { AuthGuard } from './auth/auth.guard';
import { SocketoneService,SockettwoService } from './socketconnection.service';
import { TokenInterceptor } from './service/auth/token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { FileSaverModule } from 'ngx-filesaver';


// const config: SocketIoConfig = { url: "https://illumine-dev-app.herokuapp.com/", options: {} };


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    DashboardComponent,
    ForgetpasswordComponent,
    MustMatchDirective,
    ProjectsComponent,
    ProjectdetailComponent,
    EmptystatesComponent,
    EngineerComponent,
    CustomerComponent,
    EngineerdetailComponent,
    CustomerdetailComponent,
    CardComponent,
    MyLineChartComponent,
    DocumentsComponent,
    LoadingComponent,
    MyBarChartComponent,
    MonthyearComponent,
    TotalclientComponent,
    TotalpeComponent,
    TotalrevenueComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ChartsModule,
    SocketIoModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    FileSaverModule
  ],
  providers: [CookieService, AuthGuard,LoginGuard,DashboardGuard,SocketoneService,SockettwoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
