<main style="display: flex;justify-content: center;height: 100vh;">
    <div >
        <div >
            <div class="  mb-4 h-md-250">
                <div class=" d-flex flex-column align-items-start">
                    <form name="form" (keyup.enter)="f.form.valid && login()" #f="ngForm" novalidate>
                        <h1 class="display-5" style="font-weight: 700;">Sign In <span style="font-weight: 400;">your
                                account</span></h1>
                        <p class=" card-text mb-auto">It's our mission to help you find the next big project.</p>
                        <div class="form-group" style="margin-top: 40px;">
                            <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">Your Email
                                ID</label>
                            <input type="email" autocomplete="off" class="form-control" placeholder="type here" style="padding-top: 15px; padding-bottom: 15px" name="email" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$">
                            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">Email is required</div>
                                <div *ngIf="email.errors.pattern">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="form-group" style="margin-top: 18px;">
                            <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">Your Password
                                </label>
                            <input type="password" autocomplete="off" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required minlength="6" placeholder="type here" style="padding-top: 15px; padding-bottom: 15px">
                            <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                                <div *ngIf="password.errors.required">Password is required</div>
                                <div *ngIf="password.errors.minlength">The password must contain at least 6 characters</div>
                            </div>
                        </div>

                        <!-- <p class=" card-text mb-auto proHireSubHeading" style="margin-top: 40px; ">By signing up, I agree to the <span data-toggle="modal" data-target="#termsModal" class="termstext">Terms and
                                Conditions</span></p> -->

                        <button *ngIf="submit == true" type="button" [disabled] = "f.form.invalid" (click)="login()"class="btn proHirebutton btn-block" style="margin-top: 15px;">Submit</button>
                        <button *ngIf="loading == true" class="btn proHirebutton btn-block" style="margin-top: 15px;">
                            <div class="spinner-border text-light" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                        </button>
                        <p class=" card-text mb-auto proHireSubHeading termstext " style="text-align: center;font-size: 16px;margin-top: 10px;" data-toggle="modal" data-target="#forgetpassword"> Forgot Password</p>

                    </form>
                </div>

            </div>
        </div>
    </div>

      <!--forget passeword Modal -->
  <div class="modal fade" id="forgetpassword" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"  aria-hidden="true">
    <div class="modal-dialog password-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >Forgot your password?</h5>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><img src="../../../../../assets/icons/cross.svg"alt="" ></span>
          </button> -->
        </div>
        <form name="form" (keyup.enter)="fo.form.valid && forgetpass()" #fo="ngForm" novalidate>
            <div class="modal-body">
                <p style="font-size: 14px;">Enter your email address that you used to register. We'll send you an email with a link to reset your password.</p>
                <div class="form-group" style="margin-top: 40px;">
                    <label for="exampleInputEmail1 proHireSubHeading" style="font-weight: 700; color: #0066FF">Your Registered Email
                        ID</label>
                    <input type="email" autocomplete="off" class="form-control" placeholder="type here" style="padding-top: 15px; padding-bottom: 15px" name="emailforget" [(ngModel)]="model.emailforget" #emailforget="ngModel" [ngClass]="{ 'is-invalid': fo.submitted && emailforget.invalid }" required
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$">
                    <div *ngIf="fo.submitted && emailforget.invalid" class="invalid-feedback">
                        <div *ngIf="emailforget.errors.required">Email is required</div>
                        <div *ngIf="emailforget.errors.pattern">Email must be a valid email address</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-footer reset" data-dismiss="modal">Cancel</button>
                <button type="button" [disabled]="fo.form.invalid" (click)="forgetpass()" class="btn btn-footer apply">Submit</button>
            </div>
        </form>
      </div>
    </div>
  </div>

   <!--forget passeword success Modal -->
   <div class="modal fade" id="success" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"  aria-hidden="true">
    <div class="modal-dialog password-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body" >
            <h5>Password reset email sent</h5>
            <p class="mt-4" style="font-size: 14px;">An email has been sent to your rescue email address, <b>{{resetEmail}}</b>. Follow the directions in the email to reset the password.</p>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-footer apply" data-dismiss="modal">Done</button>
        </div>
      </div>
    </div>
  </div>

  <!-- loading modal -->
  <div class="modal hide " data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" id="loadingmodal">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content loadingmodal">

            <div class="modal-body">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <span class="loadText">Loading please wait...</span>
            </div>

        </div>
    </div>
</div>
</main>