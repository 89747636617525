import { AuthService } from './../service/auth/auth.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  public dateRevenue = {
     startDate: "2020-02-01",
     endDate: ''
  }

  public year_client;
  public month_client;

  public year_pe;
  public month_pe;

  public year_lr;
  public month_lr;

  public year_pl;
  public month_pl
  
  public year_com;
  public year_rpm;

  // databinding variables

  public future_dates = new Date().toISOString().substring(0, 10)


  public totalClient = 0;
  public totalPE = 0;
  public totalRevenue = 0;

  public clientpercent = 0;
  public pepercent = 0;

  public projectperlicence = [];
  public revenueperlicence = [];
  public commissionpermonth = [];
  public revenuepermonth = [];

  public customerLoad = false;
  public engineerLoad = false;
  public revenueLoad = false;
  public revenueMonthLoad = false;
  public commisionMonthLoad = false;
  public projectLicenseLoad = false;
  public revenueLicenseLoad = false;


  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.header.next(true); 
    let yearNow = new Date().getFullYear();
    let month = new Date().getMonth() + 1;

    this.month_client = month
    this.year_client = yearNow

    this.month_pe = month
    this.year_pe = yearNow

    this.month_pl = month;
    this.year_pl = yearNow;

    this.month_lr = month;
    this.year_lr = yearNow;
   
    this.year_rpm = yearNow;
    this.year_com = yearNow;

    this.dateRevenue.endDate = new Date().toISOString().substring(0, 10) ;
    
  }
  ngAfterViewInit(){
    this.getDashboardData();
  }


getFilter(){
  this.getDashboardData();
}

  getDashboardData() {
    if( typeof(this.year_client) == 'string' ) {
      let change = parseInt(this.year_client)
      this.year_client = change ;
    }
    if( typeof(this.month_client) == 'string' ) {
      let change = parseInt(this.month_client)
      this.month_client = change;
    }
    if( typeof(this.year_pe) == 'string' ) {
      let change = parseInt(this.year_pe)
      this.year_pe = change ;
    }
    if( typeof(this.month_pe) == 'string' ) {
      let change = parseInt(this.month_pe)
      this.month_pe = change;
    }
    if( typeof(this.year_lr) == 'string' ) {
      let change = parseInt(this.year_lr)
      this.year_lr = change ;
    }
    if( typeof(this.month_lr) == 'string' ) {
      let change = parseInt(this.month_lr)
      this.month_lr = change;
    }
    if( typeof(this.year_pl) == 'string' ) {
      let change = parseInt(this.year_pl)
      this.year_pl = change;
    }
    if( typeof(this.month_pl) == 'string' ) {
      let change = parseInt(this.month_pl)
      this.month_pl = change;
    }
    if( typeof(this.year_com) == 'string' ) {
      let change = parseInt(this.year_com)
      this.year_com = change;
    }
    if( typeof(this.year_rpm) == 'string' ) {
      let change = parseInt(this.year_rpm)
      this.year_rpm = change;
    }
    
   let  sendData = {
      dateRevenue : this.dateRevenue,
      year_client: this.year_client,
      month_client: this.month_client,
      year_pe: this.year_pe,
      month_pe: this.month_pe,
      year_lr: this.year_lr,
      month_lr: this.month_lr,
      year_pl: this.year_pl,
      month_pl: this.month_pl,
      year_com: this.year_com,
      year_rpm: this.year_rpm

    }
    console.log(sendData);
    this.authService.getDashboard(sendData).subscribe((data: any) => {
      console.log(data.revenuepermonth )
      this.getload()
      this.totalClient = data.totalClients
      this.totalPE = data.totalEngineer
      this.totalRevenue = data.totalRevenue[0]?.totalRevenue || 0
      this.clientpercent = data.clientpercent[0]?.value;
      this.pepercent = data.pepercent[0]?.value;
      this.projectperlicence = data.projectperlicence
      this.revenueperlicence = data.revenueperlicence
      this.commissionpermonth = data.commissionpermonth
      this.revenuepermonth = data.revenuepermonth 

      if(data.totalRevenue.length == 0){
        this.totalRevenue == 0
      }
      
     
    }, err => this.getload())
    
  }

  getload(){
    this. customerLoad = true;
    this. engineerLoad = true;
    this. revenueLoad = true;
    this. revenueMonthLoad = true;
    this. commisionMonthLoad = true;
    this. projectLicenseLoad = true;
    this. revenueLicenseLoad = true;
  }


}