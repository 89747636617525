<div class="main-container" *ngIf="loadingbar == true; else loading">
  <div class="container">
      <div class="heading-text">
          <p>Customers<span class="image"><img src="../../assets/icons/chevron-right.svg" alt=""></span> <span style="color:  #0066FF;">Customer Details</span></p>
      </div>
      <div class="content-box mb-4">
          <div class="card">
              <div class="card-body">
                  <div class="row">
                      <div class="d-flex col-5">
                          <div class="d-flex  align-items-center">
                              <div class="mr-4">
                                  <!-- <img src="../../assets/icons/user.svg" alt="" style="width: 120px;"> -->
                              </div>
                              <div >
                                <h4>{{clientData?.client.username}}</h4>
                                <p>{{clientData?.client.email}}</p>
                              </div>
                          </div>
                      </div>
                      <div class="offset-4 col-3">
                          <div class=" d-flex justify-content-end">
                              <!-- <button type="button" style="margin: 0 13px;color: white;" class="btn btn-warning mb-2"> <img src="../../assets/icons/alert.svg" alt="" style="width: 18px; position: relative;bottom: 1px;"> Alert</button> -->
                              <button type="button" *ngIf="clientData?.client.block == false" (click)="blockOrUnblock(clientData?.client._id, clientData?.client.block)" class="btn btn-danger mb-2"> <img src="../../assets/icons/block.svg" alt="" style="width: 18px; position: relative;bottom: 1px;"> Block</button> 
                              <button type="button" *ngIf="clientData?.client.block == true" (click)="blockOrUnblock(clientData?.client._id, clientData?.client.block)" class="btn btn-primary mb-2">Unblock</button>    
  
                          </div>
                          <h5 class="text-right">Revenue Generated</h5>
                          <p class="text-right" style="font-weight: 700;font-size: 20px;">${{totalRevenue}}</p>
                      </div>
                  </div>

              </div>
          </div>
      </div>
      <h5 class="mb-4" style="font-weight:700;">Projects</h5>
      <ng-container *ngIf="clientData?.project.length !=0; else emptyStates">
        <app-card *ngFor="let clientProject of projectArr(clientData?.project)" [cardData]="clientProject"></app-card>
        <button class="btn btn-primary" *ngIf="clientData?.project.length > 5 && seeMore == false;" (click) = "seeMore = true"> See More</button>
      </ng-container>
      <ng-template #emptyStates >
          <div class="card pt-4">
            <app-emptystates [status]="'No Projects'" ></app-emptystates>
          </div>
      </ng-template>
  </div>
</div>
<ng-template #loading>
    <div style="display: flex;align-items: center;justify-content: center;height: 70vh;">
      <app-loading></app-loading>
  </div>
  </ng-template>