import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable()
export class SocketoneService extends Socket {

  constructor() {
    console.log('service one');
    super({ url: 'https://engineerapi.gotoengineers.com/', options: {} });
    
  }
}


@Injectable()
export class SockettwoService extends Socket{

  constructor() {
    super({ url: 'https://clientapi.gotoengineers.com/', options: {} });
  }
}
