import { AuthService } from './../service/auth/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  @ViewChild('search') search: ElementRef

  public email = '';
  public clientData;
  public loadingbar = false;
  public startDate;
  public endDate;
  public sendDate = {}
  public sendData = {}
  public resetBtn = false

  public pageNumberArr = []
  public pageIndex = 0
  public future_dates = new Date().toISOString().substring(0, 10)
  public states = []
  public license;
  public location;

  p: number = 1
  totalDocs = 1;
  public PEData;
  constructor(private authService: AuthService, private _FileSaverService: FileSaverService) { }

  ngOnInit(): void {
    this.authService.header.next(true)
    this.getDetails(false, this.p);
    this.states = ["Alaska",
      "Alabama",
      "Arkansas",
      "American Samoa",
      "Arizona",
      "California",
      "Colorado",
      "Connecticut",
      "District of Columbia",
      "Delaware",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Iowa",
      "Idaho",
      "Illinois",
      "Indiana",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Massachusetts",
      "Maryland",
      "Maine",
      "Michigan",
      "Minnesota",
      "Missouri",
      "Mississippi",
      "Montana",
      "North Carolina",
      " North Dakota",
      "Nebraska",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "Nevada",
      "New York",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Virginia",
      "Virgin Islands",
      "Vermont",
      "Washington",
      "Wisconsin",
      "West Virginia",
      "Wyoming"]
  }
  ngAfterViewInit() {
    const searchEvnt = fromEvent(this.search.nativeElement, 'keyup')
    searchEvnt.pipe(
      debounceTime(500)
    ).subscribe((data: any) => {
      this.email = data.target.value;
      this.emailFilter()
    })

  }
  showAlert(data: boolean[]) {

    return data.some(res => res == true)
  }

  applyFilter() {
    this.loadingbar = false;
    if (this.license == undefined && this.location == undefined && this.startDate == undefined && this.endDate == undefined) {
      this.sendData = {};
      this.getDetails(false, this.p = 1);
      this.loadingbar = true;
      return
    }
    this.sendData = {

      date: {
        startDate: this.startDate,
        endDate: this.endDate
      }
    }
    console.log(this.sendData);

    this.getDetails(true, this.p = 1);
  }

  getDetails(btn, p) {
    this.p = p
    this.authService.getClient(this.sendData, this.p).subscribe((data: any) => {
      this.totalDocs = data[0]?.metadata[0]?.total | 0
      this.loadingbar = true;
      this.PEData = data[0].data;
      console.log(data);
      this.resetBtn = btn;

    }, err => {
      this.loadingbar = true;
      this.resetBtn = btn;
    })
  }

  handleBudget(value) {

    if (value == undefined || value == null) {
      return 0
    }
    return value

  }
  emailFilter() {
    this.loadingbar = false;
    if (this.email.length != 0) {

      console.log('successs');
      this.sendData = {
        ...this.sendData, search: this.email
      }
    } else {
      delete this.sendData['search']
      console.log(this.sendData)
    }

    this.getDetails(false, this.p = 1);

  }
  filterReset() {
    this.loadingbar = false;

    this.sendData = {}
    this.startDate = undefined;
    this.endDate = undefined
    if(this.email) {
      this.sendData = {
        search: this.email
      }
    }
    this.getDetails(false, this.p = 1)
  }


  blockOrUnblock(id, data) {
    this.loadingbar = false;
    var sendData;
    if (data == true) {
      sendData = false;
    } else {
      sendData = true;
    }
    this.authService.getClientBlock(id, { block: sendData }).subscribe(data => this.getDetails(false, this.p))
  }

  sendAlertMsg(id) {
    this.getDetails(false, this.p);
    this.authService.sendAlert(id).subscribe(data => console.log(data))
  }

  pageChanged(event) {
    this.p = event;
    if (this.resetBtn == true) {
      this.applyFilter();
    } else {
      this.getDetails(this.resetBtn, this.p);

    }
  }

  // getClient(data,i,btn){
  //   this.authService.getClient(data,i).subscribe(( data:any) => {
  //     console.log(data);
  //     this.clientData = data[0].data;
  //     this.getPagination(data[0].metadata[0]?.total)
  //     this.loadingbar = true;
  //     this.resetBtn = btn;
  //   }, err => {
  //     this.loadingbar = true
  //     this.resetBtn = btn;
  //   })
  // }


  export() {
    this.authService.exportData("client").subscribe((data) => {
      const contentDisposition = data.headers.get('Content-Disposition');
      console.log(data.body);
      console.log("sdf");
      var blob = new Blob([data.body], { type: 'text/csv' });
      // var url= window.URL.createObjectURL(blob);
      // window.open(url);
      console.log(blob);
      this._FileSaverService.save(blob, 'clients.xls');


    }, (err) => console.log(err))
  }

  isDisabled() {
    if (this.startDate && this.endDate) {
      if (this.startDate > this.endDate) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }





  // blockOrUnblock(id,data){
  //   console.log(data + id);

  //   var sendData;
  //  if(data == true){
  //    sendData = false;
  //  } else {
  //    sendData = true;
  //  }
  //  this.authService.getClientBlock(id,{block:sendData}).subscribe(data => {
  //   this.loadingbar = false
  //   this.getClient(this.sendDate,this.pageIndex,false);
  //  })
  // }

}
